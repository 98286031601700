<template>
  <!-- Mobile menu -->
  <transition name="slide">
    <div
      v-if="show"
      class="sidebar hidden h-screen w-2/3 lg:flex md:flex sm:flex xs:flex flex-col fixed bg-[#1B3367] text-left z-[1500] px-10 py-10"
      style="box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25)"
    >
      <div class="w-full flex justify-end">
        <span @click="show = !show" class="hidden xs:block cursor-pointer">
          <img src="@/assets/icons/effacer-50.png" alt="" srcset="" />
        </span>
      </div>
      <ul class="text-xl font-semibold text-white">
        <li class="mb-4">
          <router-link exact to="/" class="hover:text-[#00A9E6]"
            >Accueil</router-link
          >
        </li>
        <li class="mb-4">
          <span
            @click="spanProduit = !spanProduit"
            class="cursor-pointer hover:text-[#00A9E6]"
            >Produits</span
          >
          <transition name="slide-fade">
            <ul v-show="spanProduit" class="text-base list-disc pl-5">
              <li class="mb-2">
                <router-link
                  exact
                  class="hover:text-[#00A9E6]"
                  to="/competences"
                  >Fovea erp</router-link
                >
              </li>
              <li class="mb-2">
                <router-link class="hover:text-[#00A9E6]" to="/events"
                  >Beyima crm</router-link
                >
              </li>
            </ul>
          </transition>
        </li>
        <li class="mb-4">
          <router-link exact to="/" class="hover:text-[#00A9E6]"
            >Services & Support</router-link
          >
        </li>
        <li class="mb-4">
          <router-link exact to="/" class="hover:text-[#00A9E6]"
            >Apprentissage</router-link
          >
        </li>
        <li class="mb-4">
          <router-link exact to="/" class="hover:text-[#00A9E6]"
            >À propos</router-link
          >
        </li>
      </ul>
    </div>
  </transition>
  <!-- /Mobile menu -->
  <div
    class="header fixed top-0 z-[1000] w-full h-auto flex justify-between items-center bg-white shadow-lg border-b px-10 py-5"
  >
    <div class="flex items-center">
      <div @click="gotToHome()" class="logo cursor-pointer mr-5">
        <img class="h-20" alt="Vue logo" src="@/assets/logo-beyima.png" />
      </div>
      <div class="nav-wrapper xs:hidden">
        <nav>
          <a class="inline-block hover:underline cursor-pointer mr-4"
            >Produits</a
          >
          <a class="inline-block hover:underline cursor-pointer mr-4"
            >Services & Support</a
          >
          <a class="inline-block hover:underline cursor-pointer mr-4"
            >Apprentissage</a
          >
          <a class="inline-block hover:underline cursor-pointer">À propos</a>
        </nav>
      </div>
      <span @click="show = !show" class="hidden xs:block cursor-pointer">
        <img src="@/assets/icons/menu-50.png" alt="" srcset="" />
      </span>
    </div>
    <div class="flex">
      <span @click="gotToLogin()" class="login cursor-pointer">
        <img src="@/assets/icons/user-cirle-50.png" alt="" srcset="" />
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "HeaderPage",
  data() {
    return {
      show: false,
      spanProduit: false,
    };
  },
  methods: {
    gotToLogin() {
      this.$router.push("/create-compte");
    },
    gotToHome() {
      this.$router.push("/");
    },
  },
};
</script>
<style>
.slide-enter {
  transform: translateX(-300px);
}

.slide-enter-active {
  transition: all 0.3s ease-in;
}

.slide-leave-active {
  transition: all 0.3s ease-in;
}
.slide-leave-to {
  transform: translateX(-300px);
}

.slide-two-enter {
  transform: translateX(-300px);
}

.slide-two-enter-active {
  transition: all 0.3s ease-in;
}

.slide-two-leave-active {
  transition: all 0.3s ease-in;
}
.slide-two-leave-to {
  transform: translateX(-300px);
}
</style>
