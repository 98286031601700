<template>
  <HeaderPage v-if="showHeaderFooter" />
  <router-view />
  <FooterPage v-if="showHeaderFooter" />
</template>
<script>
// @ is an alias to /src
import HeaderPage from "@/components/HeaderPage.vue";
import FooterPage from "@/components/FooterPage.vue";

export default {
  name: "HomeView",
  components: {
    HeaderPage,
    FooterPage,
  },
  data() {
    return {};
  },
  computed: {
    // Détermine si le Header et le Footer doivent être affichés
    showHeaderFooter() {
      const hiddenRoutes = ["CredAppPaiementSuccess"];
      return !hiddenRoutes.includes(this.$route.name);
    },
  },
  methods: {},
};
</script>

<style></style>
